<template>
  <div class="content">
    <PageHeader :title="'Flows'" :subtitle="'Templates'" />
    <div class="page-content container-fluid">
      <div >
        <div class="card-body">
          <div class="tab-content">
            <div class="tab-pane active" id="flows">
              <div class="row mb-2 hide">
                <div class="col-sm-12">
                  <div class="all-check-box">
                    <span class="to-left check-info">
                      <div class="control-group opt-2 to-left" style="margin-right: 5px;">
                        <label class="control control-checkbox" :for="'template-checka-all'">
                          <input
                            v-model="selectAllFlows"
                            type="checkbox"
                            class="custom-control-input"
                            :id="'template-checka-all'" />
                          <div class="control_indicator"></div>
                        </label>
                      </div>
                      Tudo
                    </span>
                    <a
                      href="javascript:void(0)"
                      class="to-left check-all-info c-pointer"
                      style="margin-left: 15px; color: #9CA7B0;"
                      @click="activeAll"
                      v-if="(selectedFlows.length) && (tab.inactiveTab === true && tab.draftTab === false)">
                      <span class="material-symbols-outlined to-left">lock_open</span>
                      <span class="to-left">Ativar {{ selectedFlows.length }}</span>
                    </a>
                    <a
                      href="javascript:void(0)"
                      class="to-left check-all-info c-pointer"
                      style="margin-left: 15px; color: #9CA7B0;"
                      @click="inactiveAll"
                      v-if="(selectedFlows.length && tab.inactiveTab === false && tab.draftTab === false)">
                      <span class="material-symbols-outlined to-left">lock</span>
                      <span class="to-left">Desativar {{ selectedFlows.length }}</span>
                    </a>
                    <a
                      href="javascript:void(0)"
                      class="to-left check-all-info c-pointer"
                      style="margin-left: 15px; color: #9CA7B0;"
                      @click="deleteAll"
                      v-if="(tab.inactiveTab === true || tab.draftTab === true) && (selectedFlows.length)">
                      <span class="material-symbols-outlined to-left">delete_forever</span>
                      <span class="to-left">Excluir itens selecionados {{ selectedFlows.length }}</span>
                    </a>
                    <div class="to-right">
                      <button v-b-modal.flow-create class="btn btn-success emit-j-event btn-new-flow" @click="isClone = false, newFlow = {}">
                        Criar novo fluxo
                      </button>
                    </div>
                    <div style="clear: both;"></div>
                  </div>
                </div>
              </div>
              <div class="text-center py-5" v-if="!fetched">
                <img src="assets/img/loading.gif">
              </div>
              <div class="row" v-else>
                <div class="col-8 mx-auto mb-5">
                  <div class="form-wrapper">
                    <form @submit.prevent="search" class="form-group col px-0 mb-0">
                      <div class="input-group">
                        <input
                          placeholder=""
                          type="text"
                          class="form-control"
                          :v-model="nameFlow"
                          ref="searchInput"
                        />
                        <div class="input-group-prepend">
                          <button @click="search" type="button" class="btn icon_btn m-0">
                            <span class="material-symbols-outlined"> search </span>
                          </button>
                        </div>
                      </div>
                    </form>
                  </div>
                </div>
                <div class="col-sm-12">
                  <br>
                  <h3>Templates</h3>
                  <br>
                </div>
                <div class="col-lg-4 col-md-4 col-sm-12" v-for="(flow, i) in flows" :key="i">
                  <div class="card-flow-template">
                    <div class="card-body relative" style="min-height: 280px !important; ">
                      <div>
                        <div class="control-group opt-2 flow-channel-item-check hide">
                          <label
                            class="control control-checkbox"
                            :for="'flow-check' + i"
                          >
                            <input
                              type="checkbox"
                              class="custom-control-input"
                              :id="'flow-check' + i"
                              :value="flow"
                              v-model="selectedFlows"
                            />
                            <div class="control_indicator"></div>
                          </label>
                        </div>
                        <div class="element-flow-item-title" style="margin-top: 10px; float: left;">
                          <span>{{ flow.name }}</span>
                          <span class="material-symbols-outlined title icon-play c-pointer" v-if="flow.video_link" @click="activeTemptate = flow" v-b-modal.flow-template-video>
                            play_circle
                          </span>
                        </div>
                        <div class="flow-channel-item-menu relative" v-if="libCheck">
                          <dropdown>
                            <template v-slot:text>
                              <span class="material-symbols-outlined title">
                                more_vert
                              </span>
                            </template>
                            <template #items>
                              <a
                                class="dropdown-item"
                                @click="edit(flow.id)"
                                >
                                <span class="material-symbols-outlined to-left">edit</span>
                                <span class="to-left left15"> Editar</span>
                                <div style="clear: both;"></div>
                              </a>
                              <a
                                class="dropdown-item"
                                @click="remove(flow.id)"
                                >
                                <span class="material-symbols-outlined to-left">delete_forever</span> <span class="to-left left15"> Excluir</span>
                                <div style="clear: both;"></div>
                              </a>
                            </template>
                          </dropdown>
                        </div>
                      </div>
                      <div class="element-flow-item-card">
                        <p style="display: block; max-height: 100px; overflow: hidden;">
                          {{ flow.description }}
                        </p>
                        <span class="span-type-flow" v-for="(tag, i) in splitTags(flow.tag_template)" :key="i">{{tag}}</span>
                      </div>
                      <div class="element-flow-item-title" style="position: absolute; bottom: 10px;">
                        <img
                          src="assets/img/icons/flow/Messenger.svg"
                          class="c-pointer"
                          style="width: 30px; height: 30px;"
                          v-if="flow.channels_template!= null && flow.channels_template.includes('messenger')">
                        <img
                          src="assets/img/icons/flow/instagram.svg"
                          class="c-pointer social-temp"
                          style="width: 30px;height: 30px;margin-left: -10px;max-width: 30px;"
                          v-if="flow.channels_template!= null && flow.channels_template.includes('instagram')">
                        <img
                          src="assets/img/icons/flow/whats_meta.png"
                          class="c-pointer social-temp"
                          style="width: 30px;height: 30px;margin-left: -10px;max-width: 30px;"
                          v-if="flow.channels_template!= null && flow.channels_template.includes('whatsapp')">
                        <img
                          src="assets/img/icons/flow/telegram.svg"
                          class="c-pointer social-temp"
                          style="width: 30px;height: 30px;margin-left: -10px;max-width: 30px;"
                          v-if="flow.channels_template!= null && flow.channels_template.includes('telegram')">
                        <img
                          src="assets/img/icons/flow/chat.svg"
                          class="c-pointer social-temp"
                          style="width: 30px; height: 30px;margin-left: -10px;max-width: 30px;"
                          v-if="flow.channels_template!= null && flow.channels_template.includes('widget')">
                        <img
                          src="assets/img/icons/flow/business_messages.svg"
                          class="c-pointer social-temp"
                          style="width: 30px; height: 30px; margin-left: -10px;max-width: 30px;"
                          v-if="flow.channels_template!= null && flow.channels_template.includes('rcs')">
                      </div>
                      <span class="refresh-date-item">
                        <a class="create_flow_btn c-pointer" @click="newFlow = flow, isClone = true" v-b-modal.flow-create>Criar Flow <span class="material-symbols-outlined to-right">keyboard_double_arrow_right</span></a>
                      </span>
                      <div style="clear:both;"></div>
                    </div>
                  </div>
                </div>
                <div class="col-sm-12 text-center no-item-flow" v-if="flows.length == 0">
                  <span class="material-icons-outlined">account_tree</span>
                  <h3>Nenhum template encontrado</h3>
                </div>
              </div>
            </div>
          </div>
        </div>
      </div>
    </div>

    <!-- modal para criar novo item -->
    <div class="flow-template-video">
      <b-modal id="flow-template-video" hide-footer class="modal-md" size="md">
        <div style="padding: 0px;">
          <div class="row">
            <div class="col-sm-12 text-center">
              <div :v-html="activeTemptate.video_link" />
              <iframe :src="activeTemptate.video_link" style="border:none;" allow="accelerometer;gyroscope;autoplay;encrypted-media;picture-in-picture" allowfullscreen=true width="360" height="720" fetchpriority="high"></iframe>
            </div>
          </div>
        </div>
      </b-modal>
    </div>

    <b-modal id="flow-create" hide-footer class="modal-lg" size="lg">
      <template #modal-title>
        Novo flow
      </template>
      <div style="padding: 20px;">
        <div class="row text-center" v-if="flowLoading">
          <img src="assets/img/loading.gif" style="margin: 0 auto;"/>
        </div>
        <div class="row" v-if="!flowLoading">
          <div class="col-sm-12 text-center">
            <h4 class="title-new-flow">Título:</h4>
          </div>
          <div class="col-sm-12">
            <input placeholder="Nome" class="form-control" v-model="newFlow.name">
            <br>
          </div>
          <div class="col-sm-12 text-center">
            <h4 class="title-new-flow">Descrição:</h4>
          </div>
          <div class="col-sm-12">
            <textarea placeholder="Descrição" class="form-control" v-model="newFlow.description"></textarea>
          </div>
          <div class="col-sm-12 text-center">
            <br>
            <h4 class="title-new-flow">Tipo de gatilho:</h4>
          </div>
          <div class="col-sm-6">
            <div class="card type-flow-item text-center" style="padding-top: 30px; padding-bottom: 35px;" @click="changeTrigger('WEBHOOK')" :class="{'type-flow-item-active': newFlow.type_trigger == 'WEBHOOK'}">
              <div style="float: left;width: 20%;">
                <img src="assets/img/icons/flow/webhook_icon.png" style="width: 70%;"/>
              </div>
              <div style="float: left; text-align: left; width: 80%;">
                <strong>Webhook</strong>
                <p style="font-weight: 200; margin-top: 5px;">Integre e dispare o flow através dos nossos Webhook.</p>
              </div>
              <div style="clear:both;"></div>
            </div>
          </div>
          <div class="col-sm-6">
            <div class="card type-flow-item text-center" style="padding-top: 30px;" @click="changeTrigger('CHANNEL')" :class="{'type-flow-item-active': newFlow.type_trigger == 'CHANNEL'}">
              <div style="float: left;width: 20%;">
                <img src="assets/img/icons/flow/channel_icon.png" style="width: 70%;"/>
              </div>
              <div style="float: left; text-align: left; width: 80%;">
                <strong>Canal</strong>
                <p style="font-weight: 200; margin-top: 5px;">Dispare o flow quando o seu cliente enviar uma mensagem através dos canais.</p>
              </div>
              <div style="clear:both;"></div>
            </div>
          </div>
        </div>
        <div v-if="!flowLoading" class="row" style="margin-top: 20px;">
          <div class="col-sm-12 text-center">
            <h4 class="title-new-flow">Escolha um canal:</h4>
          </div>
          <div class="col-sm-12">
            <div class="p20">
              <div class="row" style="padding: 10px;">
                <div class="col-sm-3" v-if="newFlow.type_trigger != 'WEBHOOK' && newFlow.channels_template!= null && newFlow.channels_template.includes('widget')">
                  <div class="text-center type-flow-item flow-channel-item-modal" v-tooltip.top="'Widget'" @click="changeChannel(widgetId, 'widget', 'chat.svg')" :class="{'type-flow-item-active': newFlow.channel_id == widgetId}">
                    <img src="assets/img/icons/flow/chat.svg" class="c-pointer"/>
                  </div>
                </div>
                <div class="col-sm-3" v-if="newFlow.type_trigger != 'WEBHOOK' && newFlow.channels_template!= null && newFlow.channels_template.includes('rcs')">
                  <div class="text-center type-flow-item flow-channel-item-modal" v-tooltip.top="'RCS'" @click="changeChannel(rcsId, 'rcs',  'rcs.svg')" :class="{'type-flow-item-active': newFlow.channel_id == rcsId}">
                    <img src="assets/img/icons/flow/rcs.svg" class="c-pointer"/>
                  </div>
                </div>
                <div class="col-sm-3" v-if="newFlow.type_trigger != 'WEBHOOK' && newFlow.channels_template!= null && newFlow.channels_template.includes('rcs')">
                  <div class="text-center type-flow-item flow-channel-item-modal" v-tooltip.top="'Google Business Messages'" @click="changeChannel(businessId, 'business', 'business_messages.svg')" :class="{'type-flow-item-active': newFlow.channel_id == businessId}">
                    <img src="assets/img/icons/flow/business_messages.svg" class="c-pointer"/>
                  </div>
                </div>
                <div class="col-sm-3" v-if="newFlow.type_trigger != 'WEBHOOK' && newFlow.channels_template!= null && newFlow.channels_template.includes('instagram')">
                  <div class="text-center type-flow-item flow-channel-item-modal" v-tooltip.top="'Instagram'" @click="changeChannel(instagramId, 'instagram', 'instagram.svg')" :class="{'type-flow-item-active': newFlow.channel_id == instagramId}">
                    <img src="assets/img/icons/flow/instagram.svg" class="c-pointer"/>
                  </div>
                </div>
                <div class="col-sm-3" v-if="newFlow.channels_template!= null && newFlow.channels_template.includes('whatsapp')">
                  <div class="text-center type-flow-item flow-channel-item-modal" v-tooltip.top="'WhatsApp'" @click="changeChannel(whatsId, 'whatsapp', 'whatsapp.svg')" :class="{'type-flow-item-active': newFlow.channel_id == whatsId}">
                    <img src="assets/img/icons/flow/WhatsApp.svg" class="c-pointer"/>
                  </div>
                </div>
                <div class="col-sm-3" v-if="newFlow.channels_template!= null && newFlow.channels_template.includes('whatsapp')">
                  <div class="text-center type-flow-item flow-channel-item-modal" v-tooltip.top="'WhatsApp Meta'" @click="changeChannel(whatsIdLive, 'whats_meta', 'whats_meta.png')" :class="{'type-flow-item-active': newFlow.channel_id == whatsIdLive}">
                    <img src="assets/img/icons/flow/whats_meta.png" class="c-pointer"/>
                  </div>
                </div>
                <div class="col-sm-3" v-if="newFlow.channels_template!= null && newFlow.channels_template.includes('telegram')">
                  <div class="text-center type-flow-item flow-channel-item-modal" v-tooltip.top="'Telegram'" @click="changeChannel(telegramId, 'telegram', 'telegram.svg')" :class="{'type-flow-item-active': newFlow.channel_id == telegramId}">
                    <img src="assets/img/icons/flow/telegram.svg" class="c-pointer" />
                  </div>
                </div>
                <div class="col-sm-3" v-if="newFlow.channels_template!= null && newFlow.channels_template.includes('messenger')">
                  <div class="text-center type-flow-item flow-channel-item-modal" v-if="newFlow.type_trigger != 'WEBHOOK'" v-tooltip.top="'Messenger'" @click="changeChannel(messengerId, 'mesenger', 'messenger.svg')" :class="{'type-flow-item-active': newFlow.channel_id == messengerId}">
                    <img src="assets/img/icons/flow/Messenger.svg" class="c-pointer"/>
                  </div>
                </div>
              </div>
            </div>
          </div>
          <div class="col-sm-12 text-center" v-if="newFlow.channel_id != widgetId">
            <h4 class="title-new-flow">Escolha uma página:</h4>
          </div>
          <div class="col-sm-12" v-if="newFlow.channel_id != whatsIdLive && newFlow.channel_id != widgetId">
            <div class="card p20">
              <div class="row" style="padding: 10px;">
                <div class="col-sm-6" v-for="(p) in this.pagesChannel" :key="p.index">
                  <div class="text-center type-flow-item" @click="changePage(p.external_id)" :class="{'type-flow-item-active': newFlow.account_channel_external_id == p.external_id}">
                    <span class="label label-info">{{p.identification_name ? p.identification_name : p.external_id}}</span>
                  </div>
                </div>
              </div>
              <div class="row" style="padding: 40px; padding-top: 10px; padding-bottom: 10px;" v-if="pagesChannel.length == 0">
                <div class="alert alert-info w-100">Nenhum item encontrado</div>
              </div>
            </div>
          </div>
          <div class="col-sm-12" v-if="newFlow.channel_id == whatsIdLive">
            <h3 style="margin-bottom: 15px;">Escolha um número</h3>
            <div class="card p20">
              <div class="row" style="padding: 10px;">
                <div class="col-sm-6" v-for="(p) in this.pagesChannel" :key="p.index">
                  <div class="text-center type-flow-item" @click="changePage(p.external_id)" :class="{'type-flow-item-active': newFlow.account_channel_external_id == p.external_id}">
                    <span class="label label-info">{{p.identification_name ? p.identification_name : p.external_id}}</span>
                  </div>
                </div>
              </div>
              <div class="row" style="padding: 40px; padding-top: 10px; padding-bottom: 10px;" v-if="pagesChannel.length == 0">
                <div class="alert alert-info w-100">Nenhum item encontrado</div>
              </div>
            </div>
          </div>
          <div class="col-sm-12" v-if="(newFlow.channel_id == whatsId || newFlow.channel_id == whatsIdLive) && newFlow.type_trigger == 'WEBHOOK'">
            <h3 style="margin-bottom: 15px;">Nome dos campos</h3>
            <div class="card p20">
              <div class="row" style="padding: 10px;">
                <div class="col-sm-4">
                  <input placeholder="Campo de nome" class="form-control" v-model="newFlow.client_name_field" />
                </div>
                <div class="col-sm-4">
                  <input placeholder="Campo de email" class="form-control" v-model="newFlow.client_email_field" />
                </div>
                <div class="col-sm-4">
                  <input placeholder="Campo de telefone" class="form-control" v-model="newFlow.client_phone_field" />
                </div>
              </div>
            </div>
          </div>
        </div>
        <div class="row" v-if="newFlow.channel_id && !flowLoading">
          <div class="col-sm-12 text-right">
            <hr>
            <button class="btn btn-new-flow color-white" @click="cloneFlow" v-if="isClone">Criar</button>
          </div>
        </div>
        <button @click="create('blank')" class="hide">{{$t('flows.blank')}}</button>
      </div>
    </b-modal>
  </div>
</template>

<script>
// @ is an alias to /src
import PageHeader from '@/components/rebranding/PageHeader.vue';
import Swal from 'sweetalert2';
import axios from '@/plugins/axios';
import ChannelService from '@/services/channel.service';
import Dropdown from '@/components/Dropdown.vue';
import Tab from '@/directives/Tab';
import $ from 'jquery';

const webApi = axios();

export default {
  name: 'ListFlows',
  components: {
    PageHeader,
    Dropdown,
  },
  directives: {
    Tab,
  },
  data() {
    return {
      activeTemptate: {},
      libCheck: false,
      fetched: false,
      nameFlow: null,
      tabLeft: '10px',
      tabActive: 'active',
      tab: {
        inactiveTab: false,
        draftTab: false,
      },
      flowLoading: false,
      instagramId: process.env.VUE_APP_INSTAGRAM_ID,
      whatsId: process.env.VUE_APP_WHATS_ID,
      whatsIdLive: process.env.VUE_APP_WHATS_META,
      messengerId: process.env.VUE_APP_MESSENGER_ID,
      telegramId: process.env.VUE_APP_TELEGRAM_ID,
      widgetId: process.env.VUE_APP_WIDGET_ID,
      rcsId: process.env.VUE_APP_RCS_ID,
      businessId: process.env.VUE_APP_BUSINESS_ID,
      flows: [],
      flowsActive: [],
      flowsInactive: [],
      flowsDraft: [],
      form: {
        q: '',
        page: 1,
      },
      pages: 1,
      isClone: false,
      newFlow: {
        name: '',
        description: '',
        channel_id: '',
        type_trigger: '',
        icon_name: '',
        account_channel_external_id: '',
      },
      selectedFlows: [],
      Toast: {},
      pagesChannel: [],
      whatsNumbers: [],
    };
  },
  computed: {
    selectAllFlows: {
      get() {
        if (this.flows) {
          return this.selectedFlows.length === this.flows.length;
        }
        return false;
      },
      set(value) {
        const selected = [];

        if (value) {
          this.flows.forEach((flow) => {
            selected.push(flow);
          });
        }

        this.selectedFlows = selected;
      },
    },
  },
  created() {
    this.fetch(1);
    this.Toast = Swal.mixin({
      toast: true,
      position: 'top-end',
      showConfirmButton: false,
      timer: 3000,
      timerProgressBar: true,
      onOpen: (toast) => {
        toast.addEventListener('mouseenter', Swal.stopTimer);
        toast.addEventListener('mouseleave', Swal.resumeTimer);
      },
    });
    this.getWhatsAccount();
    if (
      this.$store.state.account.id === '52867698-ac7b-4186-a7e9-8525161c8b7f' || this.$store.state.account.id === '29af08dd-1c1e-46e8-b19d-29ba400fed40'
    ) {
      this.libCheck = true;
    }
  },
  methods: {
    changeTabLeft(left) {
      this.tabLeft = left;
    },
    getWhatsAccount(channel) {
      this.flowLoading = true;
      this.newFlow.channel_id = channel;
      this.whatsNumbers = [];
      webApi.get('whatsapp/new/numbers').then((response) => {
        console.log('WhatsNumbers');
        console.log(response);
        this.flowLoading = false;
        response.data.forEach((data) => {
          if (data.deleted === false) {
            this.whatsNumbers.push(data);
          }
        });
      }, (error) => {
        this.content = error;
        this.flowLoading = false;
      });
    },
    changeToggle(id, active) {
      this.tabActive = active ? 'active' : 'inactive';
      this.flows = active ? this.flowsActive : this.flowsInactive;
      this.tabLeft = '10px';
      this.$forceUpdate();
      webApi.put(`flow/${id}`, {
        active,
      }).then(() => {
        this.flows = [];
        this.$toast.show({
          title: 'Successo',
          content: 'Flow atualizado',
          type: 'success',
        });
        this.fetch(1);
        this.$forceUpdate();
      }, (error) => {
        console.log(error);
        this.$toast.show({
          title: 'Erro',
          content: 'Erro interno, por favor tente novamente mais tarde ou contact o suporte para ajuda.',
          type: 'error',
        });
      });
    },
    inactiveAll() {
      Swal.fire({
        title: 'Inativar fluxos selecionados',
        text: 'Tem certeza que gostaria de inativar os fluxos?',
        icon: 'warning',
        showCancelButton: true,
        confirmButtonColor: '#3085d6',
        cancelButtonColor: '#d33',
        confirmButtonText: this.$t('generic-str.yes'),
        cancelButtonText: this.$t('app.cancel'),
      }).then((result) => {
        if (result.value) {
          const flows = [];
          this.selectedFlows.forEach((item) => {
            flows.push(item.id);
          });

          webApi.put('flows/active/false', { flows }).then((response) => {
            this.fetch(this.form.page);
            Swal.fire(
              `${this.$t('flows.inactive-success')}!`,
              'Seus fluxos foram inativados.',
              'success',
            );
            this.selectedFlows = [];
          }, (error) => {
            this.$toast.show({
              title: `${this.$t('sms.infos.filters.status.lbl-error')} ${error.code}`,
              content: error.response.data.message,
              type: 'danger',
            });
          });
        }
      });
    },
    activeAll() {
      Swal.fire({
        title: 'Ativar fluxos selecionados',
        text: 'Tem certeza que gostaria de ativar os fluxos?',
        icon: 'warning',
        showCancelButton: true,
        confirmButtonColor: '#3085d6',
        cancelButtonColor: '#d33',
        confirmButtonText: this.$t('generic-str.yes'),
        cancelButtonText: this.$t('app.cancel'),
      }).then((result) => {
        if (result.value) {
          const flows = [];
          this.selectedFlows.forEach((item) => {
            flows.push(item.id);
          });

          webApi.put('flows/active/true', { flows }).then((response) => {
            this.fetch(this.form.page);
            $('.card-tabs .nav-item .nav-link').removeClass('active');
            $('.card-tabs .nav-item:first-of-type .nav-link').addClass('active');
            Swal.fire(
              `${this.$t('flows.active-success')}!`,
              'Seus fluxos foram ativados.',
              'success',
            );
            this.selectedFlows = [];
          }, (error) => {
            this.$toast.show({
              title: `${this.$t('sms.infos.filters.status.lbl-error')} ${error.code}`,
              content: error.response.data.message,
              type: 'danger',
            });
          });
        }
      });
    },
    changeStatus(status) {
      console.log(status);
    },
    changePage(page) {
      this.newFlow.account_channel_external_id = page;
      this.$forceUpdate();
    },
    changeTrigger(trigger) {
      if (this.newFlow.channel_id !== this.widgetId && this.newFlow.channel_id !== this.rcsId && this.newFlow.channel_id !== this.businessId) {
        this.newFlow.account_channel_external_id = null;
      }
      this.newFlow.type_trigger = trigger;
      this.$forceUpdate();
    },
    changeChannel(channel, name, icon) {
      this.newFlow.channel_id = channel;
      this.newFlow.icon_name = icon;
      if (name === 'instagram') {
        this.getInstagram();
      } else if (channel !== this.widgetId) {
        this.getAccountChannel(channel);
      }
      if (channel === this.widgetId || channel === this.rcsId || channel === this.businessId) {
        this.newFlow.account_channel_external_id = this.$store.state.account.id;
      }
      this.$forceUpdate();
    },
    search(page) {
      this.form.page = page;
      this.fetched = false;

      // simulado
      this.flows = [];
      this.pages = 1;
      // simulado

      // Requisição back-end (o this.form contem a "filtagem/busca")
      webApi.get(`flow/search/${this.nameFlow}`, this.form).then((response) => {
        console.log('Flow');
        console.log(response);
        this.fetched = true;
        this.flows = [];
        response.data.forEach((flow) => {
          if (flow.template) {
            this.flows.push(flow);
          }
        });
        this.pages = response.last_page;
      }, (error) => {
        this.content = error;
      });
    },
    changeFlowListActive() {
      this.selectedFlows = [];
      this.tab.inactiveTab = false;
      this.tab.draftTab = false;
      this.flows = this.flowsActive;
      this.tabActive = 'active';
    },
    changeFlowListInactive() {
      this.selectedFlows = [];
      this.tab.inactiveTab = true;
      this.tab.draftTab = false;
      this.flows = this.flowsInactive;
      this.tabActive = 'inactive';
    },
    changeFlowListDraft() {
      this.selectedFlows = [];
      this.tab.inactiveTab = false;
      this.tab.draftTab = true;
      this.flows = this.flowsDraft;
    },
    fetch(page) {
      this.form.page = page;
      this.fetched = false;

      this.flows = [];
      this.pages = 1;
      // simulado

      // Requisição back-end (o this.form contem a "filtagem/busca")
      webApi.get('flows/templates', this.form).then((response) => {
        this.fetched = true;
        response.data.forEach((flow) => {
          if (flow.template) {
            this.flows.push(flow);
          }
        });
        this.pages = response.last_page;
      }, (error) => {
        this.content = error;
      });
    },
    saveFlow() {
      this.flowLoading = true;
      console.log(this.newFlow);
    },
    getInstagram() {
      this.flowLoading = true;
      ChannelService.instagramPage(process.env.VUE_APP_INSTAGRAM_LIVE_ID)
        .then(
          (response) => {
            console.log('Get Instagrams');
            console.log(response);
            this.pagesChannel = [];
            response.data.forEach((item) => {
              this.pagesChannel.push({
                identification_name: item.name,
                external_id: item.identification,
              });
            });
            this.flowLoading = false;
          },
          (error) => {
            this.$toast.show({
              title: 'Erro',
              content: error.response.data.message,
              type: 'danger',
            });
            this.flowLoading = false;
          },
        )
        .catch((error) => {
          console.log(error.response);
          this.flowLoading = false;
        });
    },
    getAccountChannel(channel) {
      this.flowLoading = true;
      webApi.get(`flows/account_channel/${channel}`).then((response) => {
        console.log('Channels');
        console.log(response);
        this.pagesChannel = [];
        response.data.forEach((item) => {
          if (item.deleted === 'false') {
            this.pagesChannel.push(item);
          }
        });
        this.flowLoading = false;
      }, (error) => {
        this.content = error;
        this.flowLoading = false;
      });
    },
    createFlow() {
      console.log(this.newFlow);
      if (this.newFlow.name === null || this.newFlow.name.length === 0) {
        this.$toast.show({
          type: 'danger',
          title: 'Flow',
          content: 'O nome precisa ser preenchido',
        });
        return;
      }
      if (this.newFlow.channel_id === '') {
        this.$toast.show({
          type: 'danger',
          title: 'Flow',
          content: 'Você vai precisar escolher um canal',
        });
        return;
      }
      if (this.newFlow.type_trigger === '') {
        this.$toast.show({
          type: 'danger',
          title: 'Flow',
          content: 'Você vai precisar escolher a ação',
        });
        return;
      }
      if (this.newFlow.account_channel_external_id === '') {
        this.$toast.show({
          type: 'danger',
          title: 'Flow',
          content: 'Você vai precisar escolher uma página',
        });
        return;
      }
      this.flowLoading = true;
      webApi.post('flow', this.newFlow).then((response) => {
        console.log('CreateFlow');
        console.log(response);
        const id = response.data.id;
        this.$router.push({ name: 'FlowEdit', params: { id } });
        this.flowLoading = false;
      }, (error) => {
        this.content = error;
        this.flowLoading = false;
        this.$toast.show({
          title: 'Erro',
          content: 'Erro interno, por favor tente novamente mais tarde.',
          type: 'danger',
        });
      });
    },
    splitTags(tags) {
      if (tags) {
        return tags.split(',');
      }
      return [];
    },
    cloneFlow() {
      console.log(this.newFlow);
      if (this.newFlow.name === null || this.newFlow.name.length === 0) {
        this.$toast.show({
          type: 'danger',
          title: 'Flow',
          content: 'O nome precisa ser preenchido',
        });
        return;
      }
      if (this.newFlow.channel_id === '') {
        this.$toast.show({
          type: 'danger',
          title: 'Flow',
          content: 'Você vai precisar escolher um canal',
        });
        return;
      }
      if (this.newFlow.type_trigger === '') {
        this.$toast.show({
          type: 'danger',
          title: 'Flow',
          content: 'Você vai precisar escolher a ação',
        });
        return;
      }
      if (this.newFlow.account_channel_external_id === '') {
        this.$toast.show({
          type: 'danger',
          title: 'Flow',
          content: 'Você vai precisar escolher uma página',
        });
        return;
      }
      this.flowLoading = true;
      webApi.post('flow/clone', this.newFlow).then((response) => {
        console.log('CreateFlow');
        console.log(response);
        const id = response.data.id;
        this.$router.push({ name: 'FlowEdit', params: { id } });
        this.flowLoading = false;
      }, (error) => {
        this.content = error;
        this.flowLoading = false;
      });
    },
    remove(id) {
      Swal.fire({
        title: this.$t('generic-str.r-sure'),
        text: 'O fluxo será excluído permanentemente. Você não poderá reverter isso!',
        icon: 'warning',
        showCancelButton: true,
        confirmButtonColor: '#3085d6',
        cancelButtonColor: '#d33',
        confirmButtonText: this.$t('warnings.btn-y-delete'),
      }).then((result) => {
        if (result.isConfirmed) {
          webApi.delete(`flow/${id}`).then((response) => {
            this.fetch(this.form.page);
            Swal.fire(`${this.$t('generic-str.deleted')}!`, 'Seu fluxo foi excluído.', 'success');
          }, (error) => {
            this.content = error;
          });
        }
      });
    },
    create(type) {
      switch (type) {
        case 'import':
          console.warn('Importar');
          break;
        case 'base':
          console.warn('Criar a partir de base');
          break;
        case 'blank':
          this.$router.push({ name: 'FlowCreate' });
          break;
        default:
          console.error('Nada foi selecionado');
      }
    },
    history(id) {
      console.warn(`Historico de ${id}`);
    },
    edit(id) {
      this.$router.push({ name: 'FlowEdit', params: { id } });
    },
    clone(id) {
      console.warn(`Duplicar ${id}`);
    },
    deleteAll() {
      if (!this.selectedFlows.length) return;

      Swal.fire({
        title: 'Remover fluxos selecionados',
        text: 'Tem certeza que gostaria de remover os fluxos?',
        icon: 'warning',
        showCancelButton: true,
        confirmButtonColor: '#3085d6',
        cancelButtonColor: '#d33',
        confirmButtonText: this.$t('generic-str.yes'),
      }).then((result) => {
        if (result.value) {
          const toDelete = [];
          this.selectedFlows.forEach((item) => {
            toDelete.push(item.id);
          });

          webApi.post('flows/delete-all', { flows: this.selectedFlows }).then((response) => {
            this.fetch(this.form.page);
            Swal.fire(
              `${this.$t('generic-str.deleted')}!`,
              'Seus fluxos foram excluídos.',
              'success',
            );
            this.selectedFlows = [];
          }, (error) => {
            this.$toast.show({
              title: `${this.$t('sms.infos.filters.status.lbl-error')} ${error.code}`,
              content: error.response.data.message,
              type: 'danger',
            });
          });
        }
      });
    },
  },
};
</script>

<style scoped lang="scss">
.span-type-flow {
  font-size: 13px;
  background: #e8e8e8;
  padding: 5px;
    padding-right: 5px;
    padding-left: 5px;
  padding-left: 10px;
  padding-right: 10px;
  border-radius: 8px;
  margin-right: 5px;
  display: inline-block;
  margin-top: 5px;
}
.search-btn {
  position: absolute;
  top: 5px;
  right: 12%;
  cursor: pointer;
}
.form-search {
  width: 80% !important;
  display: block;
  margin: 0 auto;
}
.create_flow_btn {
  color: var(--clr-yup-purple) !important;
  font-weight: 500 !important;
  font-size: 15px !important;
}
.card-flow-template {
  background: #fff;
  border: solid 1px #dfdfdf;
  border-radius: 10px;
  margin-bottom: 10px;
}
/* Rebranding */
.card.card-tabs .card-header .nav.nav-tabs {
  width: 100%;
  padding: 0 1rem;
  gap: 2.5rem;
}
.card.card-tabs .card-header .nav.nav-tabs li a {
  display: flex;
  align-items: center;
  gap: 5px;
  padding: 1.4rem 0.7rem 1.3rem;
  color: var(--clr-light-2);
}
.nav.nav-tabs .nav-item .nav-link.router-link-exact-active {
  color: var(--clr-yup-purple) !important;
  border-bottom: solid 2px var(--clr-yup-purple);
}

.nav.nav-tabs .nav-item .nav-link.router-link-exact-active i {
  color: var(--clr-yup-purple) !important;
}

.card.card-tabs .card-header .nav.nav-tabs li a i {
  font-size: 24px;
}

.nav-tabs .nav-item {
  margin-bottom: -2px;
}
.nav-tabs {
  border-bottom: 2px solid rgba(210, 221, 234, 0.5);
}

.custom-control-label {
  &::after,
  &::before {
    top: -0.5rem;
  }
}
.btn i {
  line-height: 1;
  color: inherit;
}
.btn {
  margin-bottom: 5px;
}
.btn-search {
  margin-left: 8px;
}
.type-flow-item {
  border-radius: 6px;
  padding: 10px;
  cursor: pointer;
  margin-bottom: 5px;
  display: block;
}
.type-flow-item:hover {
  background: #752de6;
  color: #fff;
}
.type-flow-item:hover p {
  color: #fff;
}
.type-flow-item:hover i{
  color: #fff;
}
.type-flow-item-active {
  background: #752de6;
  color: #fff;
}
.type-flow-item-active p {
  color: #fff;
}
.type-flow-item-active i {
  color: #fff;
}
.flow-trigger-icon {
  font-size: 25px;
  cursor: pointer;
}
.color-white{
  color: #fff !important;
}
.m-left-5{
  margin-left: 5px !important;
}
.flow-channel-item {
  border: solid 1px #ECEDEE;
  box-shadow: 0px 0px 12px #752DE629;
  width: 57px;
  height: 57px;
  border-radius: 4px;
  padding: 10px;
  float: left;
}
.flow-channel-item-line {
  width: 80px;
  height: 0px;
  border: 1px solid #9CA7B0;
  float: left;
  margin-top: 25px;
  margin-left: 5px;
  margin-right: 5px;
}
.element-flow-item-card {
  margin: 0 auto;
  margin-bottom: 60px;
  margin-top: 60px;
  max-height: 200px;
  min-height: 200px;
  overflow: hidden;
}
.element-flow-item-card p {
  font-size: 13px;
  color: #9b9b9b;
}
.element-flow-item-title {
  color: #0E1421;
  font-weight: 600;
  display: flex;
  flex-direction: row;
}
.refresh-date-item {
  color: #9CA7B0;
  font-size: 10px;
  position: absolute;
  right: 15px;
  bottom: 15px;
}
.flow-channel-item-check {
  float: left;
}
.flow-channel-item-menu {
  float: right;
}
.flow-channel-item-menu img{
  height: 25px;
}
.flow-channel-item-menu {
  cursor: pointer !important;
}
.btn-new-flow {
  background: #752DE6 !important;
  border: solid 1px #752DE6 !important;
}
.btn-new-flow:hover {
  background: #8238f9 !important;
}
.tab-flow ul li.active {
  color: #8238f9 !important;
}
.tab-flow {
  padding: 30px 30px 0px 0px;
  border-bottom: solid 1px #0000001A;
  width: 100%;
  margin-bottom: 20px;
}
.tab-flow ul {
  list-style: none;
  margin: 0px;
  padding-left: 15px;
  position: relative;
}
.tab-flow ul li {
  color: #423C4C;
  padding-bottom: 50px;
  float: left;
}
.tab-flow ul li {
  width: 180px;
  padding-left: 30px;
  cursor: pointer;
}
.tab-flow ul .tab-indicator{
  width: 180px;
  content: "";
  border-bottom: 2px solid #752DE6;
  display: inline-block;
  position: absolute;
  bottom: -80px;
  left: 10px;
  transition: left 0.5s;
}
.tab-flow ul li span {
  color: #423C4C;
  float: left;
  font-size: 28px !important;
}
.tab-flow ul li strong {
  color: #423C4C;
  float: left;
  font-size: 18px;
  font-weight: 200;
}

.tab-flow ul li:hover strong {
  color: #8238f9 !important;
}
.tab-flow ul li:hover span {
  color: #8238f9 !important;
}
.tab-flow ul li.active strong {
  color: #8238f9 !important;
}
.tab-flow ul li.active span {
  color: #8238f9 !important;
}
.title-new-flow {
  font-weight: 100;
}
.flow-channel-item-modal {
  border: solid 1px #ECEDEE;
  box-shadow: 0px 0px 12px #752DE629;
  border-radius: 4px;
  padding: 10px;
  width: 100%;
}
.flow-channel-item-modal img {
  width: 30% !important;
}
.top2 {
  margin-top: 2px !important;
}
.left15 {
  margin-left: 15px !important;
}
@media (max-width: 666px)
{
  .tab-indicator
   {
    display: none !important;
   }
}
.no-item-flow span{
  font-size: 70px !important;
  color: #ccc;
}
.no-item-flow h3{
  color: #ccc;
}
.no-item-flow {
  margin-top: 20px;
  margin-bottom: 20px;
}

.all-check-box {
  .check-all-info {
    color: var(--foreground);
  }
}
.p20 {
  margin-bottom: 20px !important;
}
.social-temp {
  border: 3px solid #fff;
  border-radius: 50%;
}
.form-wrapper {
  flex: 1 0 20%;
  .form-group {
    height: 55px;
  }
  .form-control,
  .input-group-prepend,
  .input-group,
  .icon_btn {
    height: 100%;
    span {
      color: var(--gray-font-color-5);
      font-size: 38px;
    }
  }
}
.darkmode .rebranding .form-group .form-control, .rebranding .form-group .form-control {
  border-right: unset !important;
}
.form-group .icon_btn{
  display: -webkit-box;
  display: -ms-flexbox;
  display: flex;
  -webkit-box-align: center;
  -ms-flex-align: center;
  align-items: center;
  background-color: #fff;
  border: 1px solid var(--form-border);
  border-left-color: transparent;
  border-radius: 0.25rem;
    border-top-left-radius: 0.25rem;
    border-bottom-left-radius: 0.25rem;
  border-top-left-radius: 0;
  border-bottom-left-radius: 0;
}
.darkmode .form-group .icon_btn {
  background-color: var(--form-input);
  border-color: var(--form-border);
  border-left-color: transparent;
}
.icon-play {
  margin-left: 5px;
  cursor: pointer;
}
.icon-play:hover {
  color: #ccc;
}
::v-deep #flow-template-video {
  .modal-content {
    background: none !important;
  }
  .modal-lg {

  }
}
</style>
